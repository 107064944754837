
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
                <v-layout row wrap px-0 mb-4>
                    <v-flex xs9>
                        <v-breadcrumbs class="px-0" :items="$store.getters.language.data.customers.edit_path">
                            <template v-slot:item="{ item }">
                                <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                    {{ item.title.toUpperCase() }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-flex>
                    <v-flex xs3>
                    </v-flex>
                </v-layout>
            <form @submit.prevent="updateCustomers" autocomplete="off">
                <v-layout row wrap>
        
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="customers.customer_name" type="text" :label="$store.getters.language.data.customers.customer_name" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="customers.customer_phone" type="text" :label="$store.getters.language.data.customers.customer_phone" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="customers.customer_catalog_text" type="text" :label="$store.getters.language.data.customers.customer_catalog_text" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg6 xl6 md3 sm4>
                        <v-text-field v-model="customers.customer_catalog_link" type="text" :label="$store.getters.language.data.customers.customer_catalog_link" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <!-- <v-flex xs12 lg12 xl12 md9 sm8>
                        <v-textarea auto-grow rows="1" v-model="customers.customer_bio" type="textarea" :label="$store.getters.language.data.customers.customer_bio" 
                            class="mx-1"  filled outlined dense  >
                        </v-textarea>
                    </v-flex> -->
                    <v-flex xs12>
                        <v-textarea v-model="customers.customer_about" auto-grow rows="4" type="textarea" :label="$store.getters.language.data.customers.customer_about" 
                            class="mx-1"  filled outlined dense  >
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 >
                        <v-textarea auto-grow rows="1" v-model="customers.customer_location" type="textarea" :label="$store.getters.language.data.customers.customer_location" 
                            class="mx-1"  filled outlined dense  >
                        </v-textarea>
                    </v-flex>
                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="customers.customer_logo" type="textarea" :label="$store.getters.language.data.customers.customer_logo" 
                            class="mx-1"  filled outlined dense  >
                        </v-text-field>
                    </v-flex> -->
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field  v-model="customers.customer_join_date" readonly type="date" :label="$store.getters.language.data.customers.customer_join_date" 
                            class="mx-1"  filled outlined dense  >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="customers.customer_exp_date" type="date" :label="$store.getters.language.data.customers.customer_exp_date" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="customers.customer_background_color" type="color" :label="$store.getters.language.data.customers.customer_background_color" 
                            class="mx-1"  filled outlined dense  >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="customers.customer_text_color" type="color" :label="$store.getters.language.data.customers.customer_text_color" 
                            class="mx-1"  filled outlined dense  >
                        </v-text-field>
                    </v-flex>
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="['true','false']" v-model="customers.customer_is_active" dense  filled outlined  item-text="customer_is_active"
                                    item-value="customer_is_active" :return-object="false" :label="$store.getters.language.data.customers.customer_is_active">
                                </v-select>
                        </v-flex>
                
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn  color="success"    type="submit">{{$store.getters.language.data.customers.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
    <v-snackbar v-model="snackbar.value" :color="snackbar.color">
        {{snackbar.text}}
    </v-snackbar>
</div>
</template>
<script>
import moment from 'moment'
    import requests from './../../requests/customers.request.js'
    export default {
        data() {
            return {
                customers: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                
            }
        },
        computed: {
           
            page(){
                const pageKey = 'CUSTOMERS'
                return {}
            }
        },
        mounted(){
            this.id = this.$route.params.id
            this.getOneCustomers()
        },
        methods: {
            getOneCustomers() {
                this.loading = true
                requests.getOneCustomers(this.id).then(r =>{
                    if (r.status == 200) {
                        this.customers = r.data.customers
                        this.customers.customer_join_date = moment(this.customers.customer_join_date).format('YYYY-MM-DD')
                        this.customers.customer_exp_date = moment(this.customers.customer_exp_date).format('YYYY-MM-DD')
                        
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Customers',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read Customers',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            updateCustomers() {
                this.loading = true
                requests.updateCustomers(this.id,this.customers).then(r => {
                    if (r.status == 200) {
                        this.snackbar = {
                            value: true,
                            text: 'Customers Updated',
                            color: 'success'
                        }
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Update Faild',
                            color: 'error'
                        }
                        this.loading = false
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Update Faild',
                        color: 'error'
                    }
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    